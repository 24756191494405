import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import ServiceList from '../components/serviceList'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'


export default function ServicesPage() {
  return (
    <Layout>
      <SEO title="Live the Dream | Value-Driven Services"/>
      <PageHeader 
        title="Our Services"
        subTitle="We offer a wide-range of services and strive to meet the expectations of our clients. Whether you need help with your home improvement project or office space, our team has the best solution and products for you."
      />
      <div className="container mx-auto my-8">
        <ServiceList full/>
      </div>
    </Layout>
  )
}
